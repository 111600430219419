import { ISeoSchema, SCHEMA_ORG_URL, ISeoSchemaDefaultValues } from "domain/SeoAndAnalytics/Seo";
import { SeoConstant } from "utils/config/seo";
import { getSiteUrl } from "utils/config/site-url.helper";
import { seoSchemaUtilities } from "./seoSchemaUtilities";

const getSchemaArticle = (schema: ISeoSchema) => {
  if (!schema || !schema.article || schema.product) {
    return null;
  }

  const articleSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "Article",
    url: schema.article?.url,
    headline: schema.article?.headline,
    datePublished: schema.article?.datePublished,
    articleSection: schema.article?.articleSection,
  };
  return seoSchemaUtilities.makeSchemaScript(articleSchema);
};

const getSchemaBlogPost = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  if (!schema || !schema.blogPost) {
    return null;
  }

  const blogPostingSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "BlogPosting",
    headline: schemaDefaultValues.blogPost.headline,
    author: [
      {
        "@type": "Organization",
        name: SeoConstant.CARFAX_NAME,
        url: getSiteUrl(),
      },
    ],
    datePublished: schema.blogPost.createdAt,
    image: schema.blogPost.image,
  };

  return seoSchemaUtilities.makeSchemaScript(blogPostingSchema);
};

const getSchemaFaqPage = (schema: ISeoSchema) => {
  if (!schema || !schema.faqPage) {
    return null;
  }

  const faqPageSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "FAQPage",
    mainEntity: schema.faqPage.mainEntity,
  };
  return seoSchemaUtilities.makeSchemaScript(faqPageSchema);
};

const getSchemaOrganization = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  const brand = schema?.organization?.brand && {
    "@type": "Brand",
    logo: schemaDefaultValues.organization.brand.logo,
    aggregateRating: schema.organization.brand?.aggregateRating && {
      "@type": "AggregateRating",
      ratingValue: schema.organization.brand.aggregateRating.ratingValue,
      reviewCount: schema.organization.brand.aggregateRating.reviewCount,
    },
  };

  const description = schema?.organization?.description;

  const organizationSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "Organization",
    name: schemaDefaultValues.organization.name,
    legalName: schemaDefaultValues.organization.legalName,
    logo: schemaDefaultValues.organization.brand.logo,
    sameAs: schemaDefaultValues.organization.sameAs,
    url: schemaDefaultValues.organization.url,
    address: {
      "@type": "PostalAddress",
      streetAddress: schemaDefaultValues.organization.address.streetAddress,
      addressLocality: schemaDefaultValues.organization.address.addressLocality,
      postalCode: schemaDefaultValues.organization.address.postalCode,
      addressCountry: schemaDefaultValues.organization.address.addressCountry,
    },
    description,
    brand,
  };

  return seoSchemaUtilities.makeSchemaScript(organizationSchema);
};

const getSchemaWebPage = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  const webpageSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "WebPage",
    name: schemaDefaultValues.webPage.name,
    description: schemaDefaultValues.webPage.description,
    inLanguage: schemaDefaultValues.webPage.inLanguage,
    breadcrumb: hasBreadCrumbs(schema) && {
      "@type": "BreadcrumbList",
      itemListElement: [schema.webPage.breadcrumb.itemListElement.map((item) => ({ "@type": "ListItem", ...item }))],
    },
  };

  return seoSchemaUtilities.makeSchemaScript(webpageSchema);
};

const hasWebPage = (schema: ISeoSchema) => {
  return schema && schema.webPage;
};

const hasBreadCrumbs = (schema: ISeoSchema) => {
  return hasWebPage(schema) && schema.webPage.breadcrumb;
};

const getSchemaWebSite = (schemaDefaultValues: ISeoSchemaDefaultValues) => {
  const websiteSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": "WebSite",
    name: schemaDefaultValues.webSite.name,
    url: schemaDefaultValues.webSite.url,
  };

  return seoSchemaUtilities.makeSchemaScript(websiteSchema);
};
const getSchemaProduct = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  if (!schema) {
    return {};
  }

  const priceCurrency = schema.product?.priceCurrency;
  const isPricingPage = schema.product?.externalPricing;
  const priceWithCurrency = schema.product?.packages?.singleReport?.price;

  const offerFromPricing = isPricingPage
    ? [
        {
          "@type": "Offer",
          name: schema.product.packages.advanced?.title,
          price: schema.product.externalPricing[0]?.price,
          priceCurrency: priceCurrency,
          availability: `${SCHEMA_ORG_URL}/InStock`,
        },
        {
          "@type": "Offer",
          name: schema.product.packages.professional?.title,
          price: schema.product.externalPricing[1]?.price,
          priceCurrency: priceCurrency,
          availability: `${SCHEMA_ORG_URL}/InStock`,
        },
      ]
    : [];

  const productSchemaValues = {
    "@context": SCHEMA_ORG_URL,
    "@type": "Product",

    name: isPricingPage ? schema.product.name : schemaDefaultValues.webPage.name,
    description: isPricingPage ? schema.product.description : schemaDefaultValues.webPage.description,
    image: schema.product?.image,
    offers: [
      {
        "@type": "Offer",
        name: schema.product?.packages?.singleReport?.title,
        price: seoSchemaUtilities.removeCurrenciesSign(priceWithCurrency),
        priceCurrency: priceCurrency,
        availability: `${SCHEMA_ORG_URL}/InStock`,
      },
      ...offerFromPricing,
    ],
  };

  if (!schema || !schema.product || schema.article) {
    return { productSchemaValues };
  }

  const schemaProduct = seoSchemaUtilities.makeSchemaScript(productSchemaValues);
  return { schemaProduct };
};

const getSchemaArticleAndProduct = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  if (!schema || !schema.product || !schema.article) {
    return null;
  }

  const { productSchemaValues } = getSchemaProduct(schema, schemaDefaultValues);

  const articleAndProductSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": ["Article", "Product"],

    headline: schema?.article?.headline,
    image: productSchemaValues?.image,
    datePublished: schema.article?.datePublished,
    name: schemaDefaultValues.webPage.name,
    description: schema.article?.description,
    author: [
      {
        "@type": "Organization",
        name: schemaDefaultValues.webSite.name,
        url: schemaDefaultValues.webSite.url,
      },
    ],
    offers: productSchemaValues?.offers,
  };
  return seoSchemaUtilities.makeSchemaScript(articleAndProductSchema);
};

const getSchemaBlogMainPage = (schema: ISeoSchema, schemaDefaultValues: ISeoSchemaDefaultValues) => {
  if (!schema || !schema.blogPosts) {
    return null;
  }

  const articleAndProductSchema = {
    "@context": SCHEMA_ORG_URL,
    "@type": ["Blog"],
    name: schemaDefaultValues.webPage.name,
    description: schemaDefaultValues.webPage.description,
    blogPost: schema.blogPosts,
  };
  return seoSchemaUtilities.makeSchemaScript(articleAndProductSchema);
};

export const seoSchema = {
  getSchemaArticle,
  getSchemaBlogPost,
  getSchemaFaqPage,
  getSchemaOrganization,
  getSchemaWebPage,
  getSchemaWebSite,
  getSchemaProduct,
  getSchemaArticleAndProduct,
  getSchemaBlogMainPage,
};
