import { useRouter } from "next/router";
import { ContentGroup } from "../dataLayerEvents.types";
import { routesUtil } from "domain/Routing/utils/routesUtil";

export const useContentGroup = (): ContentGroup => {
  const router = useRouter();

  const isHomepage = routesUtil.isHomepage(router.asPath);
  if (isHomepage) {
    return ContentGroup.HOME;
  }

  const CONTENT_GROUP_KEYWORDS = [
    "blog",
    "preview",
    "packages",
    "partner",
    "free-tools",
    "car-checks",
    "thank-you?transactionId",
    "vhr?reportId",
  ];

  const urlPath = router.asPath;
  for (const keyword of CONTENT_GROUP_KEYWORDS) {
    if (urlPath.includes(keyword)) {
      return mapKeywordToContentGroup(keyword);
    }
  }

  return ContentGroup.UNASSIGNED;
};

const mapKeywordToContentGroup = (keyword: string): ContentGroup => {
  switch (keyword) {
    case "blog":
      return ContentGroup.BLOG;
    case "preview":
      return ContentGroup.PREVIEW;
    case "packages":
      return ContentGroup.PACKAGES;
    case "partner":
      return ContentGroup.PARTNER;
    case "free-tools":
      return ContentGroup.FREE_TOOLS;
    case "car-checks":
      return ContentGroup.CAR_CHECKS;
    case "thank-you?transactionId":
      return ContentGroup.VHR_THANK_YOU;
    case "vhr?reportId":
      return ContentGroup.VHR_ACCOUNT_OR_EMAIL;
    default:
      return ContentGroup.UNASSIGNED;
  }
};
