export interface DataLayerPageViewExternal {
  event: "page_view";
  page_location: string;
  page_referrer: string;
  content_group: ContentGroup;
  page_language: string;
  vin_type: VinregType | undefined;
  vin_records: number | undefined;
  report_combination: string | undefined;
}

export interface DataLayerPageView {
  contentGroup: ContentGroup;
  pageLanguage: string;
  pageReferrer?: string;
  vinregType?: VinregType;
  vinRecords?: number;
  reportCombination?: string;
}

export interface DataLayerCalculation {
  event: string;
  errorMessage?: string;
  isVinSearch: boolean;
}

export interface DataLayerCalculationExternal {
  event: string;
  error_message?: string;
  type: string;
}

export enum ContentGroup {
  HOME = "Home",
  PREVIEW = "Preview",
  PACKAGES = "Packages",
  PARTNER = "Partner",
  BLOG = "Blog",
  FREE_TOOLS = "Free Tools",
  CAR_CHECKS = "Car Checks",
  VHR_THANK_YOU = "VHR | Thank you",
  VHR_ACCOUNT_OR_EMAIL = "VHR | Account or Email",
  VHR_PASSWORD = "VHR | Password",
  UNASSIGNED = "Unassigned",
}

export enum VinregType {
  VIN = "vin",
  LICENSE_PLATE = "license_plate",
}

export interface RecordCheckData {
  vinregType: VinregType;
  vinRecords: number;
  reportCombination: string;
}
