import { useRouter } from "next/router";
import { ISeoSchemaDefaultValues } from "domain/SeoAndAnalytics/Seo";
import { getSiteUrl } from "../../../utils/config/site-url.helper";
import { frontendUrlBuilder } from "../../../utils/routing/frontend-url-builder";

const getSchemaDefaultValues = (
  schemaDefaults: any,
  title: string,
  titleTemplate: string,
  description: string,
  language: string
): ISeoSchemaDefaultValues => {
  const router = useRouter() || { asPath: "/" };

  const compleatTitle = String(titleTemplate).replace("%s", title);
  const siteUrl = getSiteUrl();
  const websiteUrl = frontendUrlBuilder.homepageCanonicalUrl();

  return {
    organization: {
      name: schemaDefaults.organization.name,
      legalName: schemaDefaults.organization.legalName,
      url: websiteUrl,
      sameAs: schemaDefaults.organization.sameAs,
      address: schemaDefaults.organization.address,
      brand: {
        logo: `${siteUrl}/static/images/logo/logo-schema-org.png`,
      },
    },
    webSite: {
      name: schemaDefaults.webSite.name,
      url: websiteUrl,
    },
    webPage: {
      name: compleatTitle,
      description,
      inLanguage: language,
    },
    blogPost: {
      headline: title,
      url: `${siteUrl}${router.asPath}`,
      author: schemaDefaults.blogPost.author,
      publisher: {
        name: schemaDefaults.blogPost.publisher.name,
        logo: {
          name: "logo",
          width: 800,
          height: 166,
          url: `${siteUrl}/static/logo/logo-schema-org.png`,
        },
      },
    },
  };
};

export const schemaDefaultValues = {
  getSchemaDefaultValues,
};
