import Seo, {
  SCHEMA_ORG_URL,
  RobotsMeta,
  TSeoMeta,
  ISeoSchema,
  ISeoSocialMedia,
  IDataLayer,
  ISeoSchemaDefaultValues,
} from "./Seo";
export default Seo;
export { SCHEMA_ORG_URL, RobotsMeta };
export type { TSeoMeta, ISeoSchema, ISeoSocialMedia, IDataLayer, ISeoSchemaDefaultValues };
