import { linkUtil } from "../i18n/link-util";
import { Routes } from "../config/routes";
import { getSiteUrl } from "../config/site-url.helper";
import { LanguageResolver } from "../../i18n/language-resolver";
import { languageConfig } from "../../i18n/language-config";

const home = (): string => linkUtil.i18nLink(Routes.HOME);

const homeWithRedirectParameter = (redirectOrigin): string => {
  return `${linkUtil.i18nLink(Routes.HOME)}?redirect-origin="${redirectOrigin}"`;
};

const paymentRedirectBackUrl = (transactionId): string => linkUtil.i18nLink(Routes.paymentRedirect(transactionId));

const vhr = (transactionId): string => linkUtil.i18nLink(Routes.vhr(transactionId));

const vhrReport = (reportId): string => linkUtil.i18nLink(Routes.vhrReport(reportId));

const checkout = (): string => linkUtil.i18nLink(Routes.PAYMENT_CHECKOUT);

const paymentPage = (): string => linkUtil.i18nLink(Routes.PAYMENT_PAY);

const thankYouPage = (transactionId): string => linkUtil.i18nLink(Routes.thankYouWithTransactionId(transactionId));

const blogIndexPage = (): string => linkUtil.i18nLink(Routes.BLOG);

const buildCanonicalUrl = (urlSuffix: string): string => `${getSiteUrl()}${urlSuffix}`;

const homepageCanonicalUrl = (): string => `${getSiteUrl()}${frontendUrlBuilder.home()}`;

const homepageUrlWithLang = (): string => {
  const language = LanguageResolver.getCurrentLanguageShort();
  const languageUrlSubpath = languageConfig.mapLanguageToLanguageUrlSubpath(language);
  return `${getSiteUrl()}${languageUrlSubpath}`;
};

export const frontendUrlBuilder = {
  home,
  homeWithRedirectParameter,
  paymentRedirectBackUrl,
  vhr,
  vhrReport,
  checkout,
  paymentPage,
  thankYouPage,
  blogIndexPage,
  buildCanonicalUrl,
  homepageCanonicalUrl,
  homepageUrlWithLang,
};
