import { LanguageResolver } from "i18n/language-resolver";
import { useContentGroup } from "./useContentGroup";
import { DataLayerPageView } from "../dataLayerEvents.types";
import { dataLayerUtil } from "../utils/dataLayerUtil";
import { useStores } from "hooks";
import usePreviousURL from "../context/TrackingContext";

export const useDataLayerPageView = (addRecordCheckData?: boolean): DataLayerPageView => {
  const contentGroup = useContentGroup();
  const { recordCheckStore } = useStores();
  const pageLanguage = LanguageResolver.getCurrentLanguageShort().toUpperCase();
  const pageReferrer = usePreviousURL() || undefined;
  let recordCheckData;

  if (addRecordCheckData) {
    recordCheckData = dataLayerUtil.getRecordCheckData(recordCheckStore);
  }

  const dataLayerPageView: DataLayerPageView = { pageReferrer, contentGroup, pageLanguage, ...recordCheckData };

  return dataLayerPageView;
};
