import { useRouter } from "next/router";
import { getSiteUrl } from "../../../utils/config/site-url.helper";
import { getDefaultPreviewImageUrl } from "./getDefaultPreviewImageUrl";

const getSocialMediaMeta = (description: string, title: string, image: string) => {
  const router = useRouter() || { asPath: "/" };
  const siteUrl = getSiteUrl();

  const defaults = {
    description:
      "CARFAX is Europe’s largest cross-border vehicle history database and provides trusted information to millions of customers worldwide. Check your car!",
    image: getDefaultPreviewImageUrl(),
  };

  return [
    {
      name: `og:url`,
      content: `${siteUrl}${router.asPath}`,
    },
    {
      name: `og:title`,
      content: title,
    },
    {
      name: `og:description`,
      content: description || defaults.description,
    },
    {
      name: `og:image`,
      content: image || defaults.image,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description || defaults.description,
    },
    {
      name: `twitter:image`,
      content: image || defaults.image,
    },
  ];
};

export const socialMediaMeta = {
  getSocialMediaMeta,
};
