import { RecordCheckTrackingData } from "./dataLayerECommerceEvents.types";
import type { DataLayerCalculation, DataLayerPageView, DataLayerPageViewExternal } from "./dataLayerEvents.types";
import { dataLayerUtil } from "./utils/dataLayerUtil";

export enum VinSearchType {
  MANUALLY = "manually",
  PARTNER = "partner",
}

const submitPageViewEvent = ({
  pageReferrer,
  contentGroup,
  pageLanguage,
  vinregType,
  vinRecords,
  reportCombination,
}: DataLayerPageView): void => {
  const eventParameters: DataLayerPageViewExternal = {
    event: "page_view",
    page_location: window.location.href,
    page_referrer: pageReferrer,
    content_group: contentGroup,
    page_language: pageLanguage,
    vin_type: vinregType,
    vin_records: vinRecords,
    report_combination: reportCombination,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitBolloCalculationEvent = ({ event, isVinSearch, errorMessage }: DataLayerCalculation): void => {
  const eventParameters = {
    event,
    type: isVinSearch ? "with_vin" : "without_vin",
    ...(errorMessage && { error_message: errorMessage }),
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitSwitchBolloCalculatorTab = (eventWithVin: boolean): void => {
  const eventParameters = {
    event: "click_calculation_tab",
    click_text: eventWithVin ? "with_vin" : "without_vin",
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitViewSampleReportEvent = (): void => {
  const eventParameters = {
    event: "view_sample_report",
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitInputVoucherSuccessEvent = (voucherCode: string, voucherDiscountPercent: number): void => {
  const eventParameters = {
    event: "input_voucher_success",
    coupon: `${voucherCode} | ${voucherDiscountPercent}%`,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitInputVoucherErrorEvent = (voucherCode: string, voucherDiscountPercent: number): void => {
  const eventParameters = {
    event: "input_voucher_error",
    coupon: `${voucherCode} | ${voucherDiscountPercent}%`,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const vinSearchErrorEvent = (type?: string): void => {
  const eventParameters = {
    event: "vin_search_error",
    type: type ?? VinSearchType.MANUALLY,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const vinSearchSuccessEvent = (recordCheckTrackingData: RecordCheckTrackingData = {}, type?: VinSearchType): void => {
  const { reportDataTotal = undefined, vinreg = undefined, reportCombination = undefined } = recordCheckTrackingData;

  const eventParameters = {
    event: "vin_search_success",
    type: type ?? VinSearchType.MANUALLY,
    vin_records: reportDataTotal,
    vin_type: dataLayerUtil.getVinregType(vinreg),
    report_combination: reportCombination,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const confirmRadioMissingEvent = (): void => {
  const eventParameters = {
    event: "confirm_radio_missing",
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

export const dataLayerEvents = {
  submitPageViewEvent,
  submitBolloCalculationEvent,
  submitViewSampleReportEvent,
  vinSearchSuccessEvent,
  vinSearchErrorEvent,
  confirmRadioMissingEvent,
  submitInputVoucherSuccessEvent,
  submitInputVoucherErrorEvent,
  submitSwitchBolloCalculatorTab,
};
