import { Entry } from "contentful";
import { ISeoSchema } from "domain/SeoAndAnalytics/Seo";
import { IBlogPostFields, INewsPostFields } from "../../../contentful/types/generated/contentful";
import { Config } from "../../../utils/config/config";
import { SeoConstant } from "../../../utils/config/seo";
import { getSiteUrl } from "../../../utils/config/site-url.helper";
import { frontendUrlBuilder } from "../../../utils/routing/frontend-url-builder";
import { seoSchemaBlogPostUtilities } from "./seoSchemaBlogPostUtilities";

const getBlogPostSchemaParameters = (blogPost: Entry<IBlogPostFields>): ISeoSchema => {
  const blogPostFields = blogPost?.fields;
  const blogPostCategory = blogPostFields.category ? blogPostFields?.category[0]?.fields?.title : "";

  return {
    organization: {},
    webPage: {
      breadcrumb: seoSchemaBlogPostUtilities.getBreadcrumbList(blogPost),
    },
    article: {
      url: seoSchemaUtilities.getBlogUrl(blogPost),
      headline: blogPost?.fields?.title,
      datePublished: blogPost?.sys?.createdAt,
      articleSection: blogPostCategory,
    },
    blogPost: {
      articleBody: blogPost.fields.excerpt,
      createdAt: blogPost.fields.releaseDate || blogPost.sys?.createdAt,
      updatedAt: blogPost.sys?.updatedAt,
      image: blogPost.fields.featuredImage?.fields?.file?.url,
    },
  };
};

const getNewsPostSchemaParameters = (blogPost: Entry<INewsPostFields>): ISeoSchema => {
  return {
    organization: {},
    webPage: {
      breadcrumb: seoSchemaBlogPostUtilities.getBreadcrumbListForNewsArticle(blogPost),
    },
    article: {
      url: seoSchemaUtilities.getBlogUrl(blogPost),
      headline: blogPost?.fields?.title,
      datePublished: blogPost?.sys?.createdAt,
      articleSection: "testProperty", // TODO@Artur: Correct when getting feedback from Ely
    },
    blogPost: {
      articleBody: blogPost.fields.excerpt,
      createdAt: blogPost.sys?.createdAt,
      updatedAt: blogPost.sys?.updatedAt,
      image: blogPost.fields.featuredImage?.fields?.file?.url,
    },
  };
};

const getBlogUrl = (blogPost: Entry<IBlogPostFields> | Entry<INewsPostFields>): string => {
  return `${getSiteUrl()}${frontendUrlBuilder.blogIndexPage()}/${blogPost?.fields?.slug}`;
};

const getSocialMediaUrls = (): Array<string> => {
  return [
    Config.CARFAX_FACEBOOK_URL,
    Config.CARFAX_TWITTER_URL,
    Config.CARFAX_INSTAGRAM_URL,
    Config.CARFAX_YOUTUBE_URL,
    Config.CARFAX_LINKED_IN_URL,
  ];
};

const getCompanyNames = (): { name: string; legalName: string } => {
  return { name: SeoConstant.CARFAX_NAME, legalName: SeoConstant.CARFAX_LEGAL_NAME };
};

const getCompanyAddress = (): {
  streetAddress: string;
  addressLocality: string;
  postalCode: string;
  addressCountry: string;
} => {
  return {
    streetAddress: SeoConstant.STREET,
    addressLocality: SeoConstant.CITY,
    postalCode: SeoConstant.POSTAL_CODE,
    addressCountry: SeoConstant.COUNTRY,
  };
};

const makeSchemaScript = (schema: object) => {
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}></script>;
};

const removeCurrenciesSign = (price: string) => {
  const signsToRemove = ["zł", "€", "kr"];

  signsToRemove.forEach((currency) => {
    price = price?.replace(currency, "").trim();
  });

  return parseFloat(price?.replace(",", "."));
};

export const seoSchemaUtilities = {
  getBlogPostSchemaParameters,
  getNewsPostSchemaParameters,
  getBlogUrl,
  getSocialMediaUrls,
  makeSchemaScript,
  getCompanyNames,
  getCompanyAddress,
  removeCurrenciesSign,
};
