import { Routes } from "../../../utils/config/routes";
import { useTranslation, Entry } from "../../../app";
import { ISeoSchema } from "domain/SeoAndAnalytics/Seo";
import { IBlogPostFields, INewsPostFields } from "../../../contentful/types/generated/contentful";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { frontendUrlBuilder } from "../../../utils/routing/frontend-url-builder";

const getBreadcrumbList = (blogPost: Entry<IBlogPostFields>): ISeoSchema["webPage"]["breadcrumb"] => {
  const { t } = useTranslation(["page-blog"]);
  const routes = useRoutes();
  const blogPostFields = blogPost?.fields;
  const blogPostCategory = blogPostFields.category ? blogPostFields.category[0]?.fields?.title : "";
  const blogPostCategorySlug = blogPostFields.category ? blogPostFields.category[0]?.fields?.slug : "";
  const homepageWithLanguageUrl = frontendUrlBuilder.homepageUrlWithLang();

  return {
    itemListElement: [
      {
        position: 1,
        name: t("breadcrumb.home"),
        item: `${homepageWithLanguageUrl}${Routes.HOME}`,
      },
      {
        position: 2,
        name: t("breadcrumb.blog"),
        item: `${homepageWithLanguageUrl}${routes.blog}`,
      },
      {
        position: 3,
        name: blogPostCategory,
        item: `${homepageWithLanguageUrl}${Routes.category(blogPostCategorySlug)}`,
      },
      {
        position: 4,
        name: blogPost?.fields?.title,
        item: `${homepageWithLanguageUrl}${Routes.blogPost(blogPost?.fields?.slug)}`,
      },
    ],
  };
};

const getBreadcrumbListForNewsArticle = (blogPost: Entry<INewsPostFields>): ISeoSchema["webPage"]["breadcrumb"] => {
  const { t } = useTranslation(["page-blog"]);
  const routes = useRoutes();
  const homepageWithLanguageUrl = frontendUrlBuilder.homepageUrlWithLang();

  return {
    itemListElement: [
      {
        position: 1,
        name: t("breadcrumb.home"),
        item: `${homepageWithLanguageUrl}${Routes.HOME}`,
      },
      {
        position: 2,
        name: t("breadcrumb.press"),
        item: `${homepageWithLanguageUrl}${routes.press}`,
      },
      {
        position: 3,
        name: blogPost?.fields?.title,
        item: `${homepageWithLanguageUrl}${Routes.blogPost(blogPost?.fields?.slug)}`,
      },
    ],
  };
};

export const seoSchemaBlogPostUtilities = {
  getBreadcrumbList,
  getBreadcrumbListForNewsArticle,
};
